/* 外部方法 */
import { useIntervalFn } from '@vueuse/core';
import { defineStore } from 'pinia';
import * as signalR from '@microsoft/signalr';
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack';

/* 內部方法 */
import CustomError from '../models/CustomError';
import SignalrLock from '../utils/SignalrLock/index';
import SignalrLogger from '../models/SignalrLogger';

/* API */
import logRecorderService from '../api/logRecorderService';

/* 型別 */
import { LogLevel } from '@microsoft/signalr';
import type JSONString from '../interfaces/JSONString';
import type SocketPackageModel from '../interfaces/SocketPackageModel';

/** 各站連線網址後綴 */
type ConnectionRoute = 'BackendHub' | 'MessageRoom' | 'ShuffleRoom' | 'PbHub' | 'ShuffleRackHub' | 'ShuffleLeaderHub';

/** 紀錄在 ELK 上的分類名稱 */
export type LoggerSite = 'dealer' | 'tablet' | 'admin' | 'exception' | 'dealer_app' | 'shuffler';

type EventsMap = Record<string, any>;
type EventParams<Map extends EventsMap, Event extends EventNames<EventsMap>> = Parameters<Map[Event]>;
type EventNames<Map extends EventsMap> = keyof Map & string;

class ErrorNotInitialized extends Error {
  constructor() {
    super('Socket is not initialized, please use build() function first.');
  }
}

class ErrorAnonymousFunctionNotAccepted extends Error {
  constructor() {
    super('The use of anonymous functions is not accepted in SignalR lifecycle hooks.');
  }
}

interface ConnectionURL {
  domain?: string;
  route: ConnectionRoute;
}

interface SocketBuildOptions {
  loggerSite?: LoggerSite;
  reconnectRetryDelays?: number[];
  keepAliveIntervalInMilliseconds?: number;
  serverTimeoutInMilliseconds?: number;
}

class Socket<ListenEvents extends EventsMap, InvokeEvents extends EventsMap> {
  public socket?: signalR.HubConnection;
  public logger?: SignalrLogger;

  private lock = new SignalrLock();
  private onReconnectedEvents: Array<() => void> = [];
  private onReconnectingEvents: Array<() => void> = [];

  private machineId?: string;

  public setMachineId = (machineId: string) => {
    this.machineId = machineId;
  };

  /** Socket 連線狀態
   *
   * 此狀態不具有響應性（不會觸發 Vue 的畫面更新），如需響應性更新請使用以下方式：
   * const { isSocketConnected } = storeToRefs(socketStore);
   */
  public get state(): signalR.HubConnectionState | undefined {
    return this.socket?.state;
  }

  /* 連線與初始化 */
  /** 初始化 */
  public build({ domain = '', route }: ConnectionURL, token: string, options: SocketBuildOptions = {}) {
    const {
      loggerSite,
      reconnectRetryDelays = [0, 1000, 1000, 2000, 2000],
      keepAliveIntervalInMilliseconds = 10000,
      serverTimeoutInMilliseconds = 10000
    } = options;

    const socketBuilder = new signalR.HubConnectionBuilder()
      .withHubProtocol(new MessagePackHubProtocol())
      .withAutomaticReconnect(reconnectRetryDelays)
      .withUrl(`${domain}/${route}`, {
        accessTokenFactory: () => token,
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      });

    if (loggerSite) {
      this.logger = new SignalrLogger(loggerSite);
      socketBuilder.configureLogging(this.logger);
    }

    this.socket = socketBuilder.build();

    this.socket.keepAliveIntervalInMilliseconds = keepAliveIntervalInMilliseconds;
    this.socket.serverTimeoutInMilliseconds = serverTimeoutInMilliseconds;

    this.registerOnReConnectedHook();
    this.registerOnReConnectingHook();

    return this;
  }

  private registerOnReConnectedHook() {
    if (!this.socket) throw new ErrorNotInitialized();

    this.socket.onreconnected(() => {
      this.onReconnectedEvents.forEach((cb) => cb());
    });
  }

  private registerOnReConnectingHook() {
    if (!this.socket) throw new ErrorNotInitialized();

    this.socket.onreconnecting(() => {
      this.onReconnectingEvents.forEach((cb) => cb());
    });
  }

  /** 連線 */
  public async start() {
    if (!this.socket) throw new ErrorNotInitialized();

    await this.socket.start();
    this.lock.getLock();
  }

  /** 中斷連線 */
  public async stop(): Promise<void> {
    if (!this.socket) throw new ErrorNotInitialized();

    await this.socket.stop();
  }

  private convertDateStringToDateObject = <T>(obj: T): T => {
    /** 2024.6.7
     * 這支函式主要使用在 invoke 時送出給後端的物件
     * 目前的情況是在 on 的時候後端送來的時間格式會是字串，接著前端透過 parseJSON 自動轉成 Date
     * 而後端定義 DateTime 的欄位只能吃前端的 Date 格式
     *
     * 但如果觸發了斷線重連的情況，前端從 API(AJAX) 拿到的桌資料時間格式會是字串，不會轉換成 Date
     * 接著前端如果把一樣是字串的時間透過 invoke 傳給後端，就會導致 SignalR 跳出參數格式錯誤的警告
     * 經過討論後，SMS 先由前端在底層 invoke 時統一轉成 Date
     * BMS 根據後端說法則不需要有這些轉換，保持字串即可（待驗證）
     */

    const stringifiedObj = JSON.stringify(obj);
    return JSON.parse(stringifiedObj, (key, value) => {
      // !!value 可以避免意外地將 null 或 undefined 轉換為時間
      if (value) {
        // 當後端回傳的欄位結尾為 Time 或 Datetime 則視為時間並進行轉換
        return /Time$/.test(key) || /Datetime$/.test(key) ? new Date(value) : value;
      }
      return value;
    });
  };

  /* 事件相關 */
  /** 監聽事件 */
  public on<Event extends EventNames<ListenEvents>, R extends ListenEvents[Event]>(event: Event, callback: R) {
    if (!this.socket) throw new ErrorNotInitialized();

    this.socket.on(event, (args: JSONString<SocketPackageModel<R>>) => {
      const res = args.parseJSON();

      if (res.NeedAck) this.ack(res.PackageId);
      callback(res);
    });
  }

  /** 註銷監聽事件 */
  public off<Event extends EventNames<ListenEvents>>(event: Event): this {
    if (!this.socket) throw new ErrorNotInitialized();

    this.socket.off(event);
    return this;
  }

  /** 呼叫事件 */
  public async invoke<Event extends EventNames<InvokeEvents>, R extends ReturnType<InvokeEvents[Event]>>(
    event: Event,
    ...args: EventParams<InvokeEvents, Event>
  ): Promise<R> {
    if (!this.socket) throw new ErrorNotInitialized();

    logRecorderService.postLog({
      LoggerName: this.logger?.site || 'default',
      Msg: [
        {
          LogLevel: LogLevel.Trace,
          MachineID: this.machineId,
          Time: new Date(),
          Event: event,
          Args: args
        }
      ]
    });

    const res = await this.socket.invoke<R>(event, ...this.convertDateStringToDateObject(args));
    if (res && res.ErrCode !== 0) throw new CustomError('Exception', res.ErrCode.toString());
    return res;
  }

  private ack(packageId: string): void {
    this.socket?.invoke('PackageAck', packageId);
  }

  /* 生命週期事件註冊 */
  /** 連線關閉後 */
  public onClose(callback: (error?: Error | undefined) => void): this {
    if (!this.socket) throw new ErrorNotInitialized();

    this.socket.onclose((error) => {
      this.lock.unlock();
      callback(error);
    });
    return this;
  }

  private handleHookEvents(functionArray: Array<() => void>, func: () => void) {
    if (!func.name) throw new ErrorAnonymousFunctionNotAccepted();

    const index = functionArray.findIndex((f) => f.name === func.name);
    if (index >= 0) {
      functionArray[index] = func;

      console.warn(
        '[Warning] In order for SignalR lifecycle hooks to function correctly, functions with the same name will be overridden and only the latest overridden function will be executed. If this behavior is intentional, please disregard this message. Duplicate function names: ',
        func.name
      );
    } else {
      functionArray.push(func);
    }
  }

  /** 重新連線後 */
  public onReconnected(callback: (connectionId?: string) => void): this {
    if (!this.socket) throw new ErrorNotInitialized();

    this.handleHookEvents(this.onReconnectedEvents, callback);

    return this;
  }

  /** 重新連線中 */
  public onReconnecting(callback: (error?: Error) => void): this {
    if (!this.socket) throw new ErrorNotInitialized();

    this.handleHookEvents(this.onReconnectingEvents, callback);

    return this;
  }
}

//

export default <ListenEvents extends EventsMap, InvokeEvents extends EventsMap>() =>
  defineStore('socket', {
    state: () => ({
      socket: new Socket<ListenEvents, InvokeEvents>(),
      socketState: undefined as signalR.HubConnectionState | undefined,
      _socketStateInterval: undefined as any | undefined
    }),

    getters: {
      isSocketConnected(state) {
        return state.socketState === signalR.HubConnectionState.Connected;
      },

      isSocketConnecting(state) {
        return state.socketState === signalR.HubConnectionState.Connecting;
      },

      isSocketDisconnected(state) {
        return state.socketState === signalR.HubConnectionState.Disconnected;
      },

      isSocketDisconnecting(state) {
        return state.socketState === signalR.HubConnectionState.Disconnecting;
      },

      isSocketReconnecting(state) {
        return state.socketState === signalR.HubConnectionState.Reconnecting;
      }
    },

    actions: {
      startUpdateSocketState() {
        this.socketState = this.socket.state;

        this._socketStateInterval = useIntervalFn(() => {
          if (this.socketState === this.socket.state) return;
          this.socketState = this.socket.state;
        }, 1000);

        this._socketStateInterval.resume();
      },

      stopUpdateSocketState() {
        this._socketStateInterval.pause();
      }
    }
  });
